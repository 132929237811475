import React from 'react';
import CatalogJatoManage from '../CatalogJato/CatalogJatoManage';
import DaysToSendLeadsToAgencyManage from '../DaysToSendLeadsToAgency/DaysToSendLeadsToAgencyManage';

const GeneralParametersManage = () => {
  return (
    <>
      <CatalogJatoManage />
      <br />
      <DaysToSendLeadsToAgencyManage />
    </>
  );
};

export default GeneralParametersManage;
