import labels from '../../labels';
import GeneralParametersManage from './GeneralParametersManage';

export default {
  name: 'GeneralParameters',
  list: GeneralParametersManage,

  options: {
    label: labels.generalParameters,
    group: 'parameters'
  }
};
