import React, { useCallback, useEffect, useState } from 'react';
import { TextField, Button, Box } from '@material-ui/core';
import useSiteSettings from '../../utils/hooks/useSiteSettings';

const DATS_TO_SEND_LEADS_TO_AGENCY_KEY = 'days_to_send_leads_to_agency';

const DaysToSendLeadsToAgencyManage = () => {
  const [days, setDays] = useState(10); // Default to 10
  const [isSaving, setIsSaving] = useState(false);
  const { getValue, saveValue } = useSiteSettings(
    DATS_TO_SEND_LEADS_TO_AGENCY_KEY
  );

  useEffect(() => {
    const fetchInitialValue = async () => {
      try {
        const value = await getValue(); // Fetch value using useSiteSettings
        const parsedValue = parseInt(value, 10);
        setDays(!Number.isNaN(parsedValue) ? parsedValue : 10); // Default to 10 if invalid
      } catch (error) {
        console.error('Failed to fetch initial value', error);
      }
    };

    fetchInitialValue();
  }, [getValue]);

  const handleNumberChange = (event) => {
    const newValue = parseInt(event.target.value, 10);

    if (newValue >= 0 && newValue <= 15) {
      setDays(newValue); // Update the local state
    }
  };

  const handleUpdateClick = useCallback(async () => {
    setIsSaving(true);

    try {
      await saveValue(days.toString()); // Save the current value
    } catch (error) {
      console.error('Failed to update days', error);
    } finally {
      setIsSaving(false);
    }
  }, [days, saveValue]);

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <TextField
        label="מספר ימים להגבלת סטטוס הופנה על ידי נציג Carwiz"
        type="number"
        value={days}
        onChange={handleNumberChange}
        inputProps={{
          min: 0,
          max: 15
        }}
        variant="standard" // Cleaner style, no borders
        style={{ width: '200px' }} // Wider input field
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateClick}
        disabled={isSaving}
        style={{ marginRight: 15 }}
      >
        {isSaving ? 'שומר...' : 'עדכן'}
      </Button>
    </Box>
  );
};

export default DaysToSendLeadsToAgencyManage;
