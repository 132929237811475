import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import {
  Card,
  CardContent,
  Checkbox,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Box,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add'; // Import Add icon
import EditIcon from '@material-ui/icons/Edit'; // Import Edit icon
import { graphqlServiceRequest } from '../../bitComponents/utils';
import { API_URL } from '../../constants/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 400,
    overflowY: 'auto',
    border: '1px solid #ddd',
    borderRadius: 5
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  deleteButton: {
    marginLeft: theme.spacing(2)
  },
  editButton: {
    marginLeft: theme.spacing(1)
  }
}));

const TruckTransportTypeList = ({ token, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [truckTransportTypes, setTruckTransportTypes] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [newTransportTypeName, setNewTransportTypeName] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [editTransportTypeId, setEditTransportTypeId] = useState(null);

  const generateAddingQuery = () => {
    return `
      mutation {
        createTruckTransportType(input: {
          truckTransportType: {
            name: "${newTransportTypeName}"
          }
        }) {
          truckTransportType {
            id
          }
        }
      }
    `;
  };

  const generateUpdatingQuery = (id) => {
    return `
      mutation {
        updateTruckTransportTypeById(input: {patch: {name: "${newTransportTypeName}"}, id: ${id}}) {
          clientMutationId
        }
      }
    `;
  };

  const fetchData = async () => {
    setSelectedIds([]);
    setLoading(true);

    try {
      const { data } = await dataProvider.getList('TruckTransportType', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
        filter: {}
      });

      setTruckTransportTypes(data || []);
    } catch (error) {
      notify('שגיאה בשליפת סוג משא משאית', { type: 'error' });
    }

    setLoading(false);
  };

  const handleToggle = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleDelete = async () => {
    try {
      setLoading(true);

      await Promise.all(
        selectedIds.map((id) =>
          dataProvider.delete('TruckTransportType', { id })
        )
      );

      notify('סוגי משא נמחקו בהצלחה', { type: 'success' });
      setSelectedIds([]);
      fetchData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify('שגיאה במחיקת סוגי משא', { type: 'error' });
    }
  };

  const handleAdd = async () => {
    if (!newTransportTypeName.trim() || !token) return;

    // Prevent duplicate names
    if (
      truckTransportTypes.some(
        (item) =>
          item.name.toLowerCase() === newTransportTypeName.trim().toLowerCase()
      )
    ) {
      notify('הסוג משא הזה כבר קיים', { type: 'warning' });
      return;
    }

    try {
      await graphqlServiceRequest(token, generateAddingQuery(), API_URL);

      notify('סוג משא נוסף בהצלחה', { type: 'success' });
      setNewTransportTypeName('');
      setOpenDialog(false);
      fetchData();
    } catch (error) {
      notify('שגיאה בהוספת סוג משא', { type: 'error' });
    }
  };

  const handleUpdate = async () => {
    if (!newTransportTypeName.trim() || !token) return;

    // Prevent duplicate names
    if (
      truckTransportTypes.some(
        (item) =>
          item.name.toLowerCase() === newTransportTypeName.trim().toLowerCase()
      )
    ) {
      notify('הסוג משא הזה כבר קיים', { type: 'warning' });
      return;
    }

    try {
      await graphqlServiceRequest(
        token,
        generateUpdatingQuery(editTransportTypeId),
        API_URL
      );

      notify('סוג משא עודכן בהצלחה', { type: 'success' });
      setNewTransportTypeName('');
      setOpenDialog(false);
      setIsEditMode(false);
      setEditTransportTypeId(null);
      fetchData();
    } catch (error) {
      notify('שגיאה בעדכון סוג משא', { type: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card>
      <CardContent>
        <Box className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() => setOpenDialog(true)}
            startIcon={<AddIcon />} // Add the plus icon here
          >
            הוסף סוג משא
          </Button>

          {selectedIds.length > 0 && (
            <Button
              variant="contained"
              color="secondary"
              disabled={loading}
              style={{
                backgroundColor: 'red', // Red background color
                color: 'white', // White text color
                '&:hover': {
                  backgroundColor: 'darkred' // Dark red on hover
                }
              }}
              onClick={handleDelete}
              className={classes.deleteButton}
            >
              מחק ({selectedIds.length})
            </Button>
          )}
        </Box>

        {loading ? (
          <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
        ) : (
          <List className={classes.container}>
            {truckTransportTypes.map((item) => (
              <ListItem
                key={item.id}
                button
                onClick={() => handleToggle(item.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedIds.includes(item.id)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={item.name} />
                <IconButton
                  className={classes.editButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditMode(true);
                    setEditTransportTypeId(item.originalId);
                    setNewTransportTypeName(item.name);
                    setOpenDialog(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {isEditMode ? 'עדכן סוג משא' : 'הוסף סוג משא חדש'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="שם סוג משא"
            fullWidth
            value={newTransportTypeName}
            onChange={(e) => setNewTransportTypeName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setNewTransportTypeName('');
            }}
            color="secondary"
          >
            ביטול
          </Button>
          <Button
            onClick={isEditMode ? handleUpdate : handleAdd}
            color="primary"
          >
            {isEditMode ? 'עדכן' : 'שמור'}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default TruckTransportTypeList;
